<template>
  <v-row>
    <v-col sm="12" md="12" lg="6">
      <v-hover v-slot="{ hover }">
        <v-card outlined>
          <v-card-text>
            <v-btn
              retain-focus-on-click
              active-class="primary"
              fab
              @click="zoomFlag = !zoomFlag"
              :input-value="zoomFlag"
            >
              <v-icon v-if="!zoomFlag">mdi-magnify-scan</v-icon>
              <v-icon v-else>mdi-magnify-minus-outline</v-icon>
            </v-btn>
            <v-scale-transition>
              <span v-if="hover" class="transition-fast-in-fast-out ml-3">
                <v-btn
                  large
                  icon
                  color="grey"
                  @click="addProductToWishList(currentProductDetails)"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>

                <v-btn
                  large
                  icon
                  color="grey"
                  @click="addProductToCart(currentProductDetails)"
                >
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </span>
            </v-scale-transition>
          </v-card-text>
          <v-card-text
            @mouseleave="onZoomLeave"
            class="mx-auto"
            :style="{
              height: height,
              width: width,
            }"
          >
            <div v-if="!currentProductDetails.product_pic" key="sk_img">
              <v-skeleton-loader
                transition="fade-transition"
                key="img_sk1"
                type="image"
                tile
              ></v-skeleton-loader>
              <v-skeleton-loader
                transition="fade-transition"
                tile
                key="img_sk2"
                type="image"
              ></v-skeleton-loader>
            </div>
            <v-fade-transition>
              <zoom-on-hover
                v-if="currentProductDetails.product_pic"
                key="zoom_img"
                :disabled="!zoomFlag"
                :img-normal="currentProductDetails.product_pic"
              >
              </zoom-on-hover>
            </v-fade-transition>
          </v-card-text>
        </v-card>
      </v-hover>
      <v-sheet class="mt-6">
        <v-slide-group v-model="model" mandatory center-active>
          <v-slide-item v-for="n in 12" :key="n" v-slot="{ active, toggle }">
            <v-card
              outlined
              :class="active ? 'bordered-card' : ''"
              class="mr-3"
              height="150"
              width="150"
              @click="toggle"
            >
              <v-img
                src="https://cdn.shopify.com/s/files/1/0204/0483/0283/products/TD1MTHU_SHOE_ANGLE_GLOBAL_MENS_TREE_DASHERS_THUNDER_1f5d1ce6-8faa-4af0-88a7-abc76cc88057_900x900.png?v=1610063483"
              ></v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-col>
    <v-col sm="12" md="12" lg="6">
      <h1 class="text-h2 grey--text" v-html="productTitle"></h1>
      <h3 class="text-h3 black--text my-6">
        <b class="font-weight-bolder"
          >${{ currentProductDetails.cost | moneyFormat }}</b
        >
      </h3>
      <h4 class="text-h4 my-6">
        Lorem provident a facere ratione, perferendis exercitationem labore.
      </h4>
      <p class="text-body">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, quod.
        Provident eveniet fugit tempora blanditiis odio architecto. Quibusdam
        temporibus illum esse minima quidem provident a facere ratione,
        perferendis exercitationem labore.
      </p>
      <v-rating
        half-increments
        :value="rateAverage"
        class="d-inline-block my-6"
        readonly
        background-color="orange"
        color="orange"
        large
      ></v-rating>
      <span
        class="grey--text text--lighten-2 text-h6 mr-2"
        style="vertical-align: middle"
      >
        (750 Customer reviews)
      </span>
      <v-divider class="my-6"></v-divider>
      <span class="text-h6 text-capitalize">{{ catText }}: </span>
      <v-row>
        <v-col cols="12">
          <v-chip
            class="ma-2"
            label
            v-for="(category, i) in currentProductDetails.product_has_category"
            :key="`cat_chips_${i}`"
          >
            <v-icon left> mdi-tag-outline </v-icon>
            {{ category.category.category_name }}
          </v-chip>
          <v-chip
            class="ma-2"
            label
            v-for="(
              subcategory, i
            ) in currentProductDetails.product_has_sub_category"
            :key="`subcat_chips_${i}`"
          >
            <v-icon left> mdi-tag-multiple-outline </v-icon>
            {{ subcategory.sub_category.sub_category_name }}
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="grey lighten-4"> </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      model: 0,
      cats: 4,
      zoomFlag: false,
    };
  },
  computed: {
    ...mapGetters(["currentProductDetails"]),
    rateAverage() {
      const self = this;
      const avg = self.currentProductDetails.rate_average;
      let doubleAvg = avg && typeof avg == "string" ? parseFloat(avg) : 0.0;
      return doubleAvg;
    },
    productTitle() {
      const self = this;
      const pName = self.currentProductDetails.product_name ?? "";
      let title = pName ? pName.split(" ") : "";
      let temp = "";
      if (title.length) {
        title.forEach((word, i) => {
          if (i > 0 && i < 3) {
            temp += `<b class="font-weight-bolder"> ${word}</b> `;
          } else {
            temp += `${word} `;
          }
        });
      }
      return temp;
    },
    catText() {
      const self = this;
      let count = 0;
      let phc = self.currentProductDetails.product_has_category
        ? self.currentProductDetails.product_has_category.length
        : 0;
      let phsc = self.currentProductDetails.product_has_sub_category
        ? self.currentProductDetails.product_has_sub_category.length
        : 0;
      count = count + phc + phsc;
      if (count > 1) {
        return self.$t("generals.category");
      } else {
        return self.$t("generals.categorySingle");
      }
    },
    height() {
      let h = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          h = "40vh";
          break;
        case "sm":
          h = 400;
          break;
        case "md":
          h = "auto";
          break;
        case "lg":
        case "xl":
          h = "auto";
          break;
      }
      return h;
    },
    width() {
      let w = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          w = "100%";
          break;
        case "sm":
          w = 400;
          break;
        case "md":
          w = "63vw";
          break;
        case "lg":
        case "xl":
          w = "100%";
          break;
      }
      return w;
    },
  },
  methods: {
    ...mapActions(["getProductInfo", "addProductToCart"]),
    onZoomLeave() {
      const self = this;
      if (self.zoomFlag == true) {
        self.zoomFlag = false;
      }
    },
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
  },
  created() {
    const self = this;
    self.getProductInfo(self.$route.params.id);
  },
};
</script>
<style scoped>
.bordered-card {
  border: 2px solid #283374 !important;
}
</style>
